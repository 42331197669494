@import "normalize.css";
@import "./fonts/fonts.scss";
@import "./filters/cssgram.css";
@import "./variables/_colors";

:root {
	--primary: #6371de;
}

.barbie {

	h1,
	h2 {
		span {
			color: #e305ad;
		}
	}
}

::-ms-reveal {
	display: none;
}

.load {
	display: block;
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100vw;
	background: white;
}

body {
	overflow-x: hidden;
}

body,
html {
	min-height: 100%;
}

html {
	scroll-behavior: smooth;
}

body>svg {
	position: absolute;
	left: -999px;
}

.AppWrapper {
	position: relative;
	max-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 480px) {
	.AppWrapper {
		min-height: auto;
	}
}

button,
a {
	user-select: none;
	cursor: pointer;
}

#detach-button-host {
	display: none !important;
}

body {
	font-family: Poppins, "Montserrat", sans-serif;
}

.text_page {
	padding-bottom: 40px;
}

main:has(.sportCards) {
	background: #F2F3F7;
}

main {
	background: #ffffff;
	flex: 1 0 auto;
	position: relative;
	max-width: 100%;
	overflow: clip;
	z-index: 5;

	&.auth {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.error_page {
		.error_page_item {
			h1 {
				margin: 4vw 20px 3vw;
				font-size: clamp(32px, 3.03vw, 60px);
				text-align: center;
				background: linear-gradient(171deg, #43cbff 20%, #9708cc 100%) top center;
				text-shadow: 1px 1px 5px #00000017;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.btn {
			max-width: 150px;
			white-space: nowrap;
			cursor: pointer;
			display: table;
			width: 100%;
			margin: 30px auto 0;
			text-decoration: none;
			border: none;
			color: $white;
			background: #6371de;
			font-style: normal;
			font-weight: 600;
			font-size: 19px;
			line-height: 100%;
			border-radius: 5px;
			padding: 10px 21px;
			box-sizing: border-box;
			text-align: center;

			&:hover {
				opacity: 0.8;
				text-decoration: none;
			}
		}
	}

	&.blog {
		padding-top: 0;
		background-color: #f9f8fe;
	}

	&.remove_bg {
		min-height: calc(100vh - clamp(150px, 10.42vw, 200px));

		.rc-slider {
			z-index: 15;

			&-step {
				display: none;
			}

			&-rail {
				height: 100%;
				background-color: transparent;
				pointer-events: none;
				opacity: 0.8;
				z-index: 3;
			}

			&-track {
				bottom: 0;
				top: 5px;
				height: 100%;
				background: transparent;
				border-top: 5px solid $trim_track_bg;
				border-bottom: 5px solid $trim_track_bg;
				opacity: 1;
				z-index: 20;
				pointer-events: none;
				backdrop-filter: none;
			}

			&-handle {
				top: 10px;
				background-color: $trim_track_bg;
				height: 100%;
				width: 25px;
				border-radius: 0;
				border: none;
				background-image: url("/remove_back/trim/trim_handle.png");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 50%;
				pointer-events: all;
				z-index: 15;

				&-dragging {
					border: none !important;
					box-shadow: none !important;
				}

				&:hover {
					background-size: 60%;
				}

				&-1 {
					border-radius: 10px 0 0 10px;
					background-size: 50%;
					background-image: url("/remove_back/trim/trim_handle1.png");
				}

				&-2 {
					border-radius: 0 10px 10px 0;
					background-size: 50%;
					background-image: url("/remove_back/trim/trim_handle2.png");
				}
			}
		}

		&.magic_bg {
			padding-bottom: 0;
			margin-bottom: 0;

			&.loading-result {
				&:before {
					content: none;
				}
			}
		}
	}

	&.geometry_back {
		position: relative;
		background-repeat: no-repeat;
		background-position: top left 25px;
		background-color: $white;
		background-size: contain;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: calc(100vw + 27px);
			height: calc(100vw + 27px);
			border-radius: 50%;
			background: linear-gradient(141.66deg, #6371de 38.24%, rgba(99, 113, 222, 0) 87.46%);
			z-index: 0;
			transform: translate(38.4%, -61.5%);
		}

		> {
			position: relative;
		}

		&.faq {
			background-color: #f9f8fe;
		}

		&.auth {
			background-color: #f9f8fe;
			background-size: contain;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: calc(100vw + 20px);
				height: calc(75vw);
				border-radius: 50%;
				background: linear-gradient(141.66deg, #596cff 38.24%, #be63de 87.46%);
				z-index: 0;
				transform: translate(20%, -33.5%);
			}
		}

		&.index {
			.swiper {
				width: 100%;
				height: 600px;
				position: relative;
				margin: 0 auto;
			}

			.swiper-pagination {
				position: absolute;
				top: 450px;
				height: fit-content;

				span {
					margin: 0 6px 0 6px !important;
					width: 10px;
					height: 10px;
					background-color: #0249ff;
				}
			}

			.swiper-slide {
				width: 100%;
				text-align: center;
				font-size: 18px;
				background-color: transparent;
				height: 350px;
				transform-style: preserve-3d;
				backface-visibility: hidden;
				-webkit-transform-style: preserve-3d;
				-webkit-backface-visibility: hidden;

				* {
					transform-style: preserve-3d;
					backface-visibility: hidden;
					-webkit-transform-style: preserve-3d;
					-webkit-backface-visibility: hidden;
				}
			}
		}
	}

	&.empty_back {
		background-image: none;
		background-color: #f9f8fe;

		&::before {
			content: none;
		}
	}

	&.photo-into-video {
		padding-bottom: 0;
		background-color: #ffffff;
		overflow-x: clip;
		overflow-y: visible;
	}

	&.ai-main {
		padding-top: 30px;
		padding-bottom: 0;
		background-color: #ffffff;
		overflow: clip;

		.swiper {
			padding-top: 25px;
		}

		.swiper-pagination {
			margin-top: 52px;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 29px;

			&-bullet {
				margin: 0 6px 0 6px !important;
				width: clamp(16px, 1.04vw, 20px);
				height: clamp(16px, 1.04vw, 20px);
				left: 0;
				top: 0;
				background: #bcd2fb;
				transition: all 0.5s;
			}

			&-bullet-active {
				width: clamp(22px, 1.46vw, 28px);
				height: clamp(22px, 1.46vw, 28px);
				background: linear-gradient(128.75deg, #43cbff -10.96%, #9708cc 100%);
			}
		}
	}

	&.no-paddingTop {
		padding-top: 0 !important;
	}
}

@media screen and (max-width: 1200px) {
	main {
		&.geometry_back {
			&.empty_back {
				.empty-list {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 950px) {
	main {
		&.geometry_back {
			&.remove_bg {
				min-height: fit-content;
			}
		}
	}
}

section {
	animation-name: appearance;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
}

.section {
	max-width: 100%;
	box-sizing: border-box;
	padding-left: calc(50% - 1170px / 2);
	padding-right: calc(50% - 1170px / 2);

	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 45px;
		line-height: 120%;

		&.page_title {
			margin: 40px auto clamp(20px, 1.56vw, 30px);
			font-size: clamp(30px, 2.29vw, 44px);
			font-weight: 700;
			line-height: 120%;
			color: $black;

			position: relative;
			text-align: center;
		}
	}

	h1,
	h2 {
		color: #38373a;
	}

	>h2 {
		text-align: center;
		font-style: normal;
		font-weight: 600;
		font-size: 45px;
		line-height: 120%;

		span {
			font-weight: 400;
		}
	}

	h2 {
		&.page_title {
			text-align: center;
		}
	}

	&.magicBg {
		h1 {
			font-weight: 600;
			font-size: clamp(50px, 4.17vw, 80px);
			line-height: 120%;
			color: #000;
		}

		h2 {
			color: #000;
		}
	}
}

.faq-section {
	padding: 0 0 70px;
	margin: 0 auto 80px;
	width: 100%;
}

.tutorials-section {
	padding-left: 0 !important;
	padding-right: 0 !important;
	margin: 0 auto;
	width: 80%;
	background-color: #f9f8fe;
}

// формы

.request_error {
	display: flex;
	align-items: center;
	width: calc(100% - 31px);
	padding: 15px;
	box-sizing: border-box;
	background: #e85a5533;
	border-radius: 5px;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 100%;
	margin-bottom: 20px;

	img {
		margin-right: 10px;
	}
}

.auth_window {
	position: relative;
	box-sizing: border-box;
	max-width: 770px;
	height: 570px;
	width: 100%;
	padding: 30px;
	box-shadow: 5px 5px 50px #6874b6;
	background: url("/logo_bg.svg"), linear-gradient(90deg, #6371de 50%, $white 50%);
	background-repeat: no-repeat;
	background-position: left bottom;
	border-radius: 5px;
	margin: 108px auto;
	display: flex;
	animation-name: appearance;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;

	>div {
		box-sizing: border-box;
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		&.auth_title {
			font-style: normal;
			color: $white;

			h1 {
				font-weight: 400;
				font-size: 40px;
				line-height: 120%;
			}
		}
	}

	&.new_pass {
		max-width: 350px;
		background: $white;
		height: 250px;
		color: #38373a;
		margin: 100px auto 60px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 150%;
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.auth_form {
		padding-left: 31px;
	}
}

.auth_form {
	height: 100%;
	color: #38373a;

	position: relative;

	form {
		display: block;
		width: 100%;
	}

	.request_error {
		width: 100%;
		position: relative;
		top: -30px;
		margin-bottom: 0;
	}

	.form_item {
		position: relative;

		&.column {
			margin-top: 9px;
			display: flex;
			justify-content: space-between;

			>div {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 21px;
			}
		}

		.checkbox {
			position: relative;
			top: 1px;
			left: 1px;
			margin-right: 7px;
			cursor: pointer;
			border: 1px solid #cccccc;
			opacity: 0.5;

			+label {
				cursor: pointer;
			}
		}

		&.mb {
			margin-bottom: 40px;
		}

		&.mbs {
			margin-bottom: 25px;
		}

		input {
			outline: none;
		}

		input:not([type="checkbox"]) {
			box-sizing: border-box;
			width: 100%;
			border: none;
			border-bottom: 1px solid #cccccc;
			padding: 7px 4px 7px;

			+label {
				position: absolute;
				z-index: 1;
				top: 7px;
				left: 4px;
				transition: all 0.3s ease-in-out;
				user-select: none;
				cursor: text;
			}

			&:focus+label,
			&.not-empty+label {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 12px;
				top: -21px;
				color: #38373a;
				opacity: 0.54;
				cursor: default;
			}
		}

		.visibility_checkbox {
			display: none;

			+label {
				cursor: pointer;
				position: absolute;
				top: 10px;
				right: 5px;
				z-index: 1;
				height: 12px;
				display: flex;
				align-items: center;

				img {
					transition: all 0.3s ease-in-out;
					max-height: 12px;
					height: 100%;
				}
			}

			&:checked+label {
				opacity: 0.5;
			}
		}
	}

	a {
		text-decoration: none;
		color: #6371de;

		&:hover {
			text-decoration: underline;
		}
	}

	.btn {
		white-space: nowrap;
		cursor: pointer;
		display: table;
		width: 100%;
		margin: 30px auto 0;
		text-decoration: none;
		border: none;
		color: $white;
		background: #6371de;
		font-style: normal;
		font-weight: 600;
		font-size: 19px;
		line-height: 100%;
		border-radius: 5px;
		padding: 10px 21px;
		box-sizing: border-box;
		text-align: center;

		&:hover {
			opacity: 0.8;
			text-decoration: none;
		}
	}

	.ico {
		margin: 0 auto;
	}

	.text {
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		line-height: 130%;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 15px;
	}

	.form_text {
		margin-top: 30px;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 150%;

		+.form_item {
			margin-top: 47px;
		}

		a {
			text-transform: lowercase;
		}
	}

	.form_info {
		margin-top: 30px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
	}
}

.error,
.error_msg {
	color: #e85955;
}

.error_msg {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	position: absolute;
	top: calc(100% + 3px);
}

.file-drop-target {
	padding: clamp(25px, 2.86vw, 55px) 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.bold {
	font-weight: 600;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-width: 8px;
	border-style: solid;
	border-radius: 50%;
	border-color: $white transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

form {
	input {
		&:disabled {
			pointer-events: none;
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	button {
		&:disabled {
			pointer-events: none;
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}

// cookies

.cookies_wrapper,
.alert {
	z-index: 1000;
	position: fixed;
	width: 100%;
	padding: 10px 20%;
	box-sizing: border-box;
	bottom: 0;
	background: #38373ae6;
	box-shadow: 0px -1px 3px #cccccc;
	justify-content: space-between;
	align-items: center;
	display: none;
	font-style: normal;
	font-weight: normal;
	color: #cccccc;

	.cookies_text {
		font-size: 14px;
		margin-right: 10px;

		a {
			text-decoration: underline;
			color: $white;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&.on {
		display: flex;
	}

	.btn {
		font-size: 16px;
		background: $white;
		border-radius: 5px;
		color: #38373a;
		border: none;
		padding: 5px 8px;
		white-space: nowrap;
	}
}

.grecaptcha-badge {
	display: none !important;
}

.link {
	text-decoration: none;
	color: #6371de;

	&:hover {
		text-decoration: underline;
	}
}

.alert {
	position: fixed;
	top: 0;
	bottom: auto;
	z-index: 999;
	background: #38373ae6;
	box-shadow: 0 1px 3px #cccccc;
	width: 100%;
	padding: 10px 20%;

	.cookies_text {
		display: inline-flex;
		align-items: center;

		img {
			margin-right: 15px;
		}
	}
}

@media screen and (max-width: 768px) {

	.cookies_wrapper,
	.alert {
		flex-direction: column;
		align-items: flex-start;
		padding: 10px 10px;

		.cookies_text {
			font-size: 13px;
			margin-right: 0;
			margin-bottom: 8px;
		}

		.btn {
			font-size: 14px;
		}
	}

	.grecaptcha-badge {
		margin-bottom: 85px;
	}
}

@media screen and (max-width: 1170px) {
	.section {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media screen and (max-width: 1024px) {
	.section {
		&.magicBg {
			h1 {
				font-size: clamp(34px, 4.1vw, 42px);
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.section {
		padding-left: 12px;
		padding-right: 12px;

		>h2 {
			font-size: 36px;
			line-height: 44px;
		}

		h1 {
			&.page_title {
				font-size: 30px;
				line-height: 150%;
			}
		}
	}

	.auth_window {
		background: #6371de url(/logo_bg.svg) no-repeat;
		padding: 0;
		margin: 100px auto 200px;
		flex-direction: column;
		height: auto;
		box-sizing: border-box;
		border-radius: 0;

		>div.auth_title {
			margin: 0 auto;
			width: 90%;

			h1 {
				text-align: center;
				font-size: 18px;
				width: 100%;
			}
		}

		.auth_form {
			padding: 40px 30px 70px;
			background: $white;
			width: 100%;
			box-sizing: border-box;

			.text {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	main {
		&.remove_bg {
			padding-bottom: 20px;
		}

		&.geometry_back {
			&.index {
				.swiper-pagination {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	main {
		&.remove_bg {
			height: fit-content;
			min-height: 100vh;
			box-sizing: border-box;
		}
	}
}

input:-internal-autofill-selected+label {
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 12px !important;
	line-height: 12px !important;
	top: -21px !important;
}

input:-webkit-autofill {
	background: $white !important;
}

input:-webkit-autofill+label {
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 12px !important;
	line-height: 12px !important;
	top: -21px !important;
}

::-webkit-credentials-auto-fill-button {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	right: 0;
}

.loading_ellipsis {
	margin-left: 5px;
	display: inline-block;
	color: inherit;

	span {
		margin-right: 3px;
		display: inline-block;
		animation: loading_ellipsis 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;

		&:nth-child(1) {
			animation-delay: 0.2s;
		}

		&:nth-child(2) {
			animation-delay: 0.4s;
		}

		&:nth-child(3) {
			animation-delay: 0.6s;
		}
	}
}

@keyframes loading_ellipsis {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@media screen and (min-width: 1020px) {
	.adaptive {
		display: none;
	}
}

@media screen and (max-width: 1019px) {
	.desktop {
		display: none;
	}
}

.loading_circle {
	width: 200px;
	height: 200px;
	background-image: url("/ico/circle_mobile_loading.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;
	transform-origin: center;
	animation: loading_circle 2.5s linear infinite;
}

@keyframes loading_circle {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

video {
	pointer-events: none;
}

.text_page_title {
	text-align: center;
}

.anchor {
	position: absolute;
	top: -120px;
}

.rc-slider-vertical .rc-slider-mark {
	left: -45px !important;
	top: 0 !important;
}

body.hidden {
	overflow: hidden;
}

header.hidden {
	opacity: 0.02;
	pointer-events: none;
	z-index: 0;
}

footer.hidden {
	opacity: 0.02;
	pointer-events: none;
	z-index: 0;
}

.api {
	.swagger-ui .info {

		h2,
		h3,
		h4,
		h5 {
			color: #38373a;
		}
	}

	.info hgroup.main {
		text-align: center;
	}

	h2 {
		display: inline-block;
	}

	.swagger-ui .renderedMarkdown code {
		color: #6371de;
	}

	.brace-open,
	.brace-close,
	.inner-object {
		padding: 5px 0;
		display: block;
	}

	.model {
		td {
			padding: 5px !important;
		}
	}
}

:root {
	--scroll-padding: 0px;
}

body.openFullVideo {
	overflow: hidden;

	header,
	footer,
	.main {
		border-right: var(--scroll-padding) solid transparent;
	}
}

@keyframes appearance {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.faq_big {
	width: 80% !important;
	margin: 20px auto !important;
}

.relative {
	position: relative;

	&.itemScrollbars {
		min-height: 96px;
	}
}

// scrollbar style

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #a8afb5 #ececec;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
	height: 12px;
	border-radius: 20px;
}

*::-webkit-scrollbar-track {
	background: #ececec;
	border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
	background-color: #a8afb5;
	border-radius: 20px;
	border: 3px solid #ececec;
}

.imgAlt {
	position: relative;

	img {
		display: none;
	}

	&::after {
		font-weight: 600;
		font-size: clamp(22px, 1.67vw, 32px);
		line-height: 120%;
		content: "Ooops...\0aReview didn’t load.\0a Please, upload the file again.";
		white-space: pre-wrap;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($black, 0.25);
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		width: 100%;
		height: 100%;
		color: $white;
		text-transform: uppercase;
	}
}

// Slider tooltip

.rc-slider-tooltip-inner {
	background: $white !important;
	padding: 10px 5px !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 20px !important;
	color: $black !important;
	width: 80px !important;
	height: 40px !important;
	box-sizing: border-box !important;
}

.rc-slider-tooltip-arrow {
	display: none !important;
}

@media screen and (max-width: 950px) {
	.rc-slider-tooltip-inner {
		color: #999999 !important;
		box-shadow: 0 0 2px rgba(35, 40, 79, 0.2), -1px 2px 6px rgba(35, 40, 79, 0.2) !important;
		border-radius: 12px !important;
	}
}

@media screen and (max-width: 768px) {
	main {
		&.index {
			.swiper {
				width: 768px;
			}
		}

		&.geometry_back {
			&.auth {
				background-position: top left 80px;

				&:before {
					width: calc(100vw + 200px);
					height: calc(100vw);
					background: linear-gradient(141.66deg, #596cff 38.24%, #be63de 87.46%);
					transform: translate(20%, -20.5%);
				}
			}

			&.remove_bg {
				.rc-slider {
					&-handle {
						height: 60px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	main {
		&.remove_bg {
			.page_title {
				display: none;
			}

			&.editor-adaptive {
				padding: 20px 0 0 0;
				height: fit-content;
				min-height: fit-content;
			}
		}

		&.geometry_back {
			&:before {
				width: calc(100vw + 200px);
				height: calc(100vw + 400px);
				transform: translate(50.4%, -51.5%);
			}

			&.auth {
				background-position: top left 20px;
			}

			&.index {
				.swiper {
					width: 90%;
					height: 400px;
				}

				.swiper-slide {
					width: 80%;
				}
			}
		}

		&.remove_bg {
			&.magic_bg {
				margin-bottom: 0;
			}
		}
	}

	.rc-slider-tooltip-inner {
		font-size: 16px !important;
	}

	.section {
		&.avatar-layout {
			margin-bottom: 0;

			h1 {
				font-size: 40px;
			}
		}

		h1.page_title {
			padding: 0;
		}
	}
}

.promo-mail {
	text-decoration: none;
	color: #6371de;

	&:hover {
		opacity: 0.8;
	}
}