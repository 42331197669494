.aden {
	position: relative;
	-webkit-filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2);
	filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2);
}
.aden:after,
.aden:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.aden img {
	width: 100%;
	z-index: 1;
}
.aden:before {
	z-index: 2;
}
.aden:after {
	z-index: 3;
}
.aden::after {
	background: -webkit-linear-gradient(left, rgba(66, 10, 14, 0.2), transparent);
	background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
	mix-blend-mode: darken;
}
.gingham::after,
.perpetua::after,
.reyes::after {
	mix-blend-mode: soft-light;
}
.inkwell {
	position: relative;
	-webkit-filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1);
	filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1);
}
.inkwell:after,
.inkwell:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.inkwell img {
	width: 100%;
	z-index: 1;
}
.inkwell:before {
	z-index: 2;
}
.inkwell:after {
	z-index: 3;
}
.perpetua {
	position: relative;
}
.perpetua:after,
.perpetua:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.perpetua img {
	width: 100%;
	z-index: 1;
}
.perpetua:before {
	z-index: 2;
}
.perpetua:after {
	z-index: 3;
}
.perpetua::after {
	background: -webkit-linear-gradient(top, #005b9a, #e6c13d);
	background: linear-gradient(to bottom, #005b9a, #e6c13d);
	opacity: 0.5;
}
.reyes {
	position: relative;
	-webkit-filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75);
	filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75);
}
.reyes:after,
.reyes:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.reyes img {
	width: 100%;
	z-index: 1;
}
.reyes:before {
	z-index: 2;
}
.reyes:after {
	z-index: 3;
}
.reyes::after {
	background: #efcdad;
	opacity: 0.5;
}
.gingham {
	position: relative;
	-webkit-filter: brightness(1.05) hue-rotate(-10deg);
	filter: brightness(1.05) hue-rotate(-10deg);
}
.gingham:after,
.gingham:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.gingham img {
	width: 100%;
	z-index: 1;
}
.gingham:before {
	z-index: 2;
}
.gingham:after {
	z-index: 3;
}
.gingham::after {
	background: #e6e6fa;
}
.toaster {
	position: relative;
	-webkit-filter: contrast(1.5) brightness(0.9);
	filter: contrast(1.5) brightness(0.9);
}
.toaster:after,
.toaster:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.toaster img {
	width: 100%;
	z-index: 1;
}
.toaster:before {
	z-index: 2;
}
.toaster:after {
	z-index: 3;
}
.toaster::after {
	background: -webkit-radial-gradient(circle, #804e0f, #3b003b);
	background: radial-gradient(circle, #804e0f, #3b003b);
	mix-blend-mode: screen;
}
.walden {
	position: relative;
	-webkit-filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6);
	filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6);
}
.walden:after,
.walden:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.walden img {
	width: 100%;
	z-index: 1;
}
.walden:before {
	z-index: 2;
}
.walden:after {
	z-index: 3;
}
.walden::after {
	background: #04c;
	mix-blend-mode: screen;
	opacity: 0.3;
}
.hudson {
	position: relative;
	-webkit-filter: brightness(1.2) contrast(0.9) saturate(1.1);
	filter: brightness(1.2) contrast(0.9) saturate(1.1);
}
.hudson:after,
.hudson:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.hudson img {
	width: 100%;
	z-index: 1;
}
.hudson:before {
	z-index: 2;
}
.hudson:after {
	z-index: 3;
}
.hudson::after {
	background: -webkit-radial-gradient(circle, #a6b1ff 50%, #342134);
	background: radial-gradient(circle, #a6b1ff 50%, #342134);
	mix-blend-mode: multiply;
	opacity: 0.5;
}
.earlybird {
	position: relative;
	-webkit-filter: contrast(0.9) sepia(0.2);
	filter: contrast(0.9) sepia(0.2);
}
.earlybird:after,
.earlybird:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.earlybird img {
	width: 100%;
	z-index: 1;
}
.earlybird:before {
	z-index: 2;
}
.earlybird:after {
	z-index: 3;
}
.earlybird::after {
	background: -webkit-radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
	background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
	mix-blend-mode: overlay;
}
.mayfair {
	position: relative;
	-webkit-filter: contrast(1.1) saturate(1.1);
	filter: contrast(1.1) saturate(1.1);
}
.mayfair:after,
.mayfair:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.mayfair img {
	width: 100%;
	z-index: 1;
}
.mayfair:before {
	z-index: 2;
}
.mayfair:after {
	z-index: 3;
}
.mayfair::after {
	background: -webkit-radial-gradient(40% 40%, circle, rgba(255, 255, 255, 0.8), rgba(255, 200, 200, 0.6), #111 60%);
	background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.8), rgba(255, 200, 200, 0.6), #111 60%);
	mix-blend-mode: overlay;
	opacity: 0.4;
}
.lofi {
	position: relative;
	-webkit-filter: saturate(1.1) contrast(1.5);
	filter: saturate(1.1) contrast(1.5);
}
.lofi:after,
.lofi:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.lofi img {
	width: 100%;
	z-index: 1;
}
.lofi:before {
	z-index: 2;
}
.lofi:after {
	z-index: 3;
}
.lofi::after {
	background: -webkit-radial-gradient(circle, transparent 70%, #222 150%);
	background: radial-gradient(circle, transparent 70%, #222 150%);
	mix-blend-mode: multiply;
}
.bright {
	position: relative;
	-webkit-filter: saturate(1.1) contrast(1.5);
	filter: saturate(1.1) contrast(1.5);
}
.bright:after,
.bright:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.bright img {
	width: 100%;
	z-index: 1;
}
.bright:before {
	z-index: 2;
}
.bright:after {
	z-index: 3;
}
._1977 {
	position: relative;
	-webkit-filter: contrast(1.1) brightness(1.1) saturate(1.3);
	filter: contrast(1.1) brightness(1.1) saturate(1.3);
}
._1977:after,
._1977:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
._1977 img {
	width: 100%;
	z-index: 1;
}
._1977:before {
	z-index: 2;
}
._1977:after {
	z-index: 3;
	background: rgba(243, 106, 188, 0.3);
	mix-blend-mode: screen;
}
.lightpink {
	position: relative;
	-webkit-filter: contrast(1) brightness(1) saturate(1);
	filter: contrast(1) brightness(1) saturate(1);
}
.lightpink:after,
.lightpink:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.lightpink img {
	width: 100%;
	z-index: 1;
}
.lightpink:before {
	z-index: 2;
}
.lightpink:after {
	z-index: 3;
	background: rgba(243, 106, 188, 0.3);
	mix-blend-mode: screen;
}
.brooklyn {
	position: relative;
	-webkit-filter: contrast(0.9) brightness(1.1);
	filter: contrast(0.9) brightness(1.1);
}
.brooklyn:after,
.brooklyn:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.brooklyn img {
	width: 100%;
	z-index: 1;
}
.brooklyn:before {
	z-index: 2;
}
.brooklyn:after {
	z-index: 3;
}
.brooklyn::after {
	background: -webkit-radial-gradient(circle, rgba(168, 223, 193, 0.4) 70%, #c4b7c8);
	background: radial-gradient(circle, rgba(168, 223, 193, 0.4) 70%, #c4b7c8);
	mix-blend-mode: overlay;
}
.xpro2 {
	position: relative;
	-webkit-filter: sepia(0.3);
	filter: sepia(0.3);
}
.xpro2:after,
.xpro2:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.xpro2 img {
	width: 100%;
	z-index: 1;
}
.xpro2:before {
	z-index: 2;
}
.xpro2:after {
	z-index: 3;
}
.xpro2::after {
	background: -webkit-radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, 0.6) 110%);
	background: radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, 0.6) 110%);
	mix-blend-mode: color-burn;
}
.nashville {
	position: relative;
	-webkit-filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2);
	filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2);
}
.nashville:after,
.nashville:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.nashville img {
	width: 100%;
	z-index: 1;
}
.nashville:before {
	z-index: 2;
}
.nashville:after {
	z-index: 3;
}
.nashville::after {
	background: rgba(0, 70, 150, 0.4);
	mix-blend-mode: lighten;
}
.nashville::before {
	background: rgba(247, 176, 153, 0.56);
	mix-blend-mode: darken;
}
.lark {
	position: relative;
	-webkit-filter: contrast(0.9);
	filter: contrast(0.9);
}
.lark:after,
.lark:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.lark img {
	width: 100%;
	z-index: 1;
}
.lark:before {
	z-index: 2;
}
.lark:after {
	z-index: 3;
}
.lark::after {
	background: rgba(242, 242, 242, 0.8);
	mix-blend-mode: darken;
}
.lark::before {
	background: #22253f;
	mix-blend-mode: color-dodge;
}
.moon {
	position: relative;
	-webkit-filter: grayscale(1) contrast(1.1) brightness(1.1);
	filter: grayscale(1) contrast(1.1) brightness(1.1);
}
.moon:after,
.moon:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.moon img {
	width: 100%;
	z-index: 1;
}
.moon:before {
	z-index: 2;
}
.moon:after {
	z-index: 3;
}
.moon::before {
	background: #a0a0a0;
	mix-blend-mode: soft-light;
}
.moon::after {
	background: #383838;
	mix-blend-mode: lighten;
}
.clarendon {
	position: relative;
	-webkit-filter: contrast(1.2) saturate(1.35);
	filter: contrast(1.2) saturate(1.35);
}
.clarendon:after,
.clarendon:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.clarendon img {
	width: 100%;
	z-index: 1;
}
.clarendon:before {
	z-index: 2;
	background: rgba(127, 187, 227, 0.2);
	mix-blend-mode: overlay;
}
.clarendon:after {
	z-index: 3;
}
.willow {
	position: relative;
	-webkit-filter: brightness(1.2) contrast(0.85) saturate(0.05) sepia(0.2);
	filter: brightness(1.2) contrast(0.85) saturate(0.05) sepia(0.2);
}

.willow img {
	width: 100%;
	z-index: 1;
}
.rise {
	position: relative;
	-webkit-filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9);
	filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9);
}
.rise:after,
.rise:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.rise img {
	width: 100%;
	z-index: 1;
}
.rise:before {
	z-index: 2;
}
.rise:after {
	z-index: 3;
}
.rise::after {
	background: -webkit-radial-gradient(circle, rgba(232, 197, 152, 0.8), transparent 90%);
	background: radial-gradient(circle, rgba(232, 197, 152, 0.8), transparent 90%);
	mix-blend-mode: overlay;
	opacity: 0.6;
}
.rise::before {
	background: -webkit-radial-gradient(circle, rgba(236, 205, 169, 0.15) 55%, rgba(50, 30, 7, 0.4));
	background: radial-gradient(circle, rgba(236, 205, 169, 0.15) 55%, rgba(50, 30, 7, 0.4));
	mix-blend-mode: multiply;
}
.slumber {
	position: relative;
	-webkit-filter: saturate(0.66) brightness(1.05);
	filter: saturate(0.66) brightness(1.05);
}
.slumber:after,
.slumber:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.slumber img {
	width: 100%;
	z-index: 1;
}
.slumber:before {
	z-index: 2;
}
.slumber:after {
	z-index: 3;
}
.slumber::after {
	background: rgba(125, 105, 24, 0.5);
	mix-blend-mode: soft-light;
}
.slumber::before {
	background: rgba(69, 41, 12, 0.4);
	mix-blend-mode: lighten;
}
.brannan {
	position: relative;
	-webkit-filter: sepia(0.5) contrast(1.4);
	filter: sepia(0.5) contrast(1.4);
}
.brannan:after,
.brannan:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.brannan img {
	width: 100%;
	z-index: 1;
}
.brannan:before {
	z-index: 2;
}
.brannan:after {
	z-index: 3;
}
.brannan::after {
	background-color: rgba(161, 44, 199, 0.31);
	mix-blend-mode: lighten;
}
.valencia {
	position: relative;
	-webkit-filter: contrast(1.08) brightness(1.08) sepia(0.08);
	filter: contrast(1.08) brightness(1.08) sepia(0.08);
}
.valencia:after,
.valencia:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.valencia img {
	width: 100%;
	z-index: 1;
}
.valencia:before {
	z-index: 2;
}
.valencia:after {
	z-index: 3;
}
.valencia::after {
	background: #3a0339;
	mix-blend-mode: exclusion;
	opacity: 0.5;
}
.kelvin {
	position: relative;
}
.kelvin:after,
.kelvin:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.kelvin img {
	width: 100%;
	z-index: 1;
}
.kelvin:before {
	z-index: 2;
}
.kelvin:after {
	z-index: 3;
}
.kelvin::after {
	background: #b77d21;
	mix-blend-mode: overlay;
}
.kelvin::before {
	background: #382c34;
	mix-blend-mode: color-dodge;
}
.maven {
	position: relative;
	-webkit-filter: sepia(0.25) brightness(0.95) contrast(0.95) saturate(1.5);
	filter: sepia(0.25) brightness(0.95) contrast(0.95) saturate(1.5);
}
.maven:after,
.maven:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.maven img {
	width: 100%;
	z-index: 1;
}
.maven:before {
	z-index: 2;
}
.maven:after {
	z-index: 3;
	background: rgba(3, 230, 26, 0.2);
	mix-blend-mode: hue;
}
.stinson {
	position: relative;
	-webkit-filter: contrast(0.75) saturate(0.85) brightness(1.15);
	filter: contrast(0.75) saturate(0.85) brightness(1.15);
}
.stinson:after,
.stinson:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.stinson img {
	width: 100%;
	z-index: 1;
}
.stinson:before {
	z-index: 2;
}
.stinson:after {
	z-index: 3;
}
.stinson::before {
	background: rgba(240, 149, 128, 0.2);
	mix-blend-mode: soft-light;
}
.ludwig {
	filter: sepia(0.4) contrast(1.05) brightness(0.8) saturate(1.4);
}
.ludwig::after {
	background: rgba(125, 105, 24, 0.1);
	content: "";
	mix-blend-mode: overlay;
}
.poprocket {
	filter: sepia(0.15) brightness(1.2);
}
.poprocket::after {
	background: radial-gradient(circle closest-corner, rgba(223, 21, 58, 0.5) 40%, rgba(0, 0, 0, 1) 80%);
	content: "";
	mix-blend-mode: screen;
}
.kodachrome {
	position: relative;
	-webkit-filter: contrast(1.1) brightness(1.1) saturate(1.3);
	filter: contrast(1.1) brightness(1.1) saturate(1.3);
}
.kodachrome:after,
.kodachrome:before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.kodachrome img {
	width: 100%;
	z-index: 1;
}
.kodachrome:before {
	z-index: 2;
}
.kodachrome:after {
	z-index: 3;
	background: rgba(247, 160, 160, 0.3);
}
.skyline {
	filter: contrast(1.25) brightness(1.25) saturate(1.2);
}
