/* ErrorBoundary.module.css */
.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f9fafb;
}

.card {
  max-width: 28rem;
  width: 100%;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
}

.content {
  margin-bottom: 1.5rem;
}

.header {
  margin-bottom: 1rem;
}

.title {
  color: #dc2626;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.message {
  color: #4b5563;
  margin-bottom: 1.5rem;
}

.message p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.message p:last-child {
  margin-bottom: 0;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
  width: 100%;
}

.buttonPrimary {
  background-color: #2563eb;
  color: white;
}

.buttonPrimary:hover {
  background-color: #1d4ed8;
}

.buttonSecondary {
  background-color: #f3f4f6;
  color: #4b5563;
}

.buttonSecondary:hover {
  background-color: #e5e7eb;
}

/* Media query for larger screens */
@media (min-width: 640px) {
  .actions {
    flex-direction: row;
    justify-content: space-between;
  }

  .button {
    width: auto;
  }
}